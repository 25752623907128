<template>
  <div class="mb-2">
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1">
      <v-row class="">
        <v-col cols="12" class="text-center">
          <center>
            <v-card color="primary" width="100" shaped elevation="2">
              <v-card-text>
                <v-img
                  :src="require('@/assets/images/logos/logo-primary-white.png')"
                  width="70px"
                  alt="logo"
                  contain
                  eager
                  class="app-logo"
                ></v-img>
              </v-card-text>
            </v-card>
          </center>
        </v-col>
        <v-col cols="12" class="text-center pt-0">
          <p class="black--text title font-weight-bold mb-0">Vice Digital Creative</p>
          <p class="text-14">Influencer & KOL Management Platform</p>
        </v-col>
        <v-col cols="12" class="d-flex justify-space-around pt-0">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            elevation="0"
            @click="openLink('instagram')"
          >
            <i class="fab fa-instagram text-18"></i>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            elevation="0"
            @click="openLink('facebook')"
          >
            <i class="fab fa-facebook-square text-18"></i>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            elevation="0"
            @click="openLink('tiktok')"
          >
            <i class="fab fa-tiktok text-18"></i>
          </v-btn>
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            elevation="0"
            @click="openLink('linkedin')"
          >
            <i class="fab fa-linkedin text-18"></i>
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-center pt-2">
          <p class="text-14 mb-0">&copy; 2023 All Rights Reserved</p>
        </v-col>
      </v-row>
    </div>
    <!-- DESKTOP -->
    <div v-else>
      <v-card class="primary round" elevation="0">
        <v-row class="pt-3 pb-3">
          <v-col cols="12" class="text-center">
            <center>
              <v-card color="white" width="100" shaped elevation="2">
                <v-card-text>
                  <v-img
                    :src="require('@/assets/images/logos/logo-primary.png')"
                    width="70px"
                    alt="logo"
                    contain
                    eager
                    class="app-logo"
                  ></v-img>
                </v-card-text>
              </v-card>
            </center>
          </v-col>
          <v-col cols="12" class="text-center pt-0 white--text ">
            <p class="title font-weight-bold mb-0">Vice Digital Creative</p>
            <p class="text-14">Influencer & KOL Management Platform</p>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-around pt-0">
            <v-btn
              rounded
              color="white"
              elevation="0"
              class="text-lowercase primary--text"
              @click="openLink('instagram')"
            >
              <i class="fab fa-instagram text-18 mr-2"></i> vicedigitalcreative
            </v-btn>
            <v-btn
              rounded
              color="white"
              elevation="0"
              class="text-lowercase primary--text"
              @click="openLink('facebook')"
            >
              <i class="fab fa-facebook-square text-18 mr-2"></i> vicedigitalcreative
            </v-btn>
            <v-btn
              rounded
              color="white"
              elevation="0"
              class="text-lowercase primary--text"
              @click="openLink('tiktok')"
            >
              <i class="fab fa-tiktok text-18 mr-2"></i> vicedigitalcreative
            </v-btn>
            <v-btn
              rounded
              color="white"
              elevation="0"
              class="text-lowercase primary--text"
              @click="openLink('linkedin')"
            >
              <i class="fab fa-linkedin text-18 mr-2"></i> Vice Digital Creative
            </v-btn>
          </v-col>
          <v-col cols="12" class="white--text text-center pt-5">
            <p class="text-14 mb-0">&copy; 2023 All Rights Reserved</p>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      isMobileWidth:0
    }
  },
  mounted(){
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    openLink(val){
      if(val === 'instagram'){
        window.open('https://www.instagram.com/vicedigitalcreative/', '_blank');
      } else if(val === 'linkedin'){
        window.open('https://www.linkedin.com/company/vice-digital-creative', '_blank');
      } else if(val === 'facebook'){
        window.open('https://www.facebook.com/vicedigitalcreative/', '_blank');
      } else if(val === 'tiktok'){
        window.open('https://vt.tiktok.com/ZGJSB4Gnk/', '_blank');
      }
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>
